import React, { useState, useEffect } from "react";
import { Fade } from "react-reveal";
import GeneratedSignature from "./GeneratedSignature";
import VideoComponent from "./VideoComponent";
import loadinganimation from "../video/Habitat-Logo-Animation-V2.mp4";
import defaultEmployeeImage from "../img/agencyhabitat.png";
// import Airtable from "./Airtable";

// // Import all images from the "../img/culture" folder
// const contextC = require.context("../img/culture", false, /\.(jpg|jpeg|png)$/);
// const cultureImages = {};
// contextC.keys().forEach((key) => {
//   const fileName = key.replace("./", "");
//   cultureImages[fileName] = contextC(key);
// });
// let usedImages = [];

// // Function to get two unique random images from the array
// const getRandomCultureImages = () => {
//   let availableImages = Object.values(cultureImages);

//   // Remove images that have already been used
//   availableImages = availableImages.filter(
//     (image) => !usedImages.includes(image)
//   );

//   // If all images have been used, reset the used images array
//   if (availableImages.length < 2) {
//     usedImages = [...Object.values(cultureImages)];
//     availableImages = [...Object.values(cultureImages)];
//   }

//   // Select two random images from the available images
//   const randomIndex1 = Math.floor(Math.random() * availableImages.length);
//   const image1 = availableImages[randomIndex1];
//   availableImages.splice(randomIndex1, 1);

//   const randomIndex2 = Math.floor(Math.random() * availableImages.length);
//   const image2 = availableImages[randomIndex2];

//   // Mark the selected images as used
//   usedImages.push(image1, image2);
//   return [image1, image2];
// };

// // Import employee headshots
// const employeeHeadshots = {};
// const context = require.context("../img/headshots", false, /\.(jpg|jpeg|png)$/);
// context.keys().forEach((key) => {
//   const fileName = key.replace("./", "");
//   employeeHeadshots[fileName] = context(key);
// });

function EmailSignatureForm() {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [copied, setCopied] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    phoneNumber: "",
    color: "#D4DFD7",
  });
  // const [cultureImages, setCultureImages] = useState([]);

  // useEffect(() => {
  //   // Call getRandomCultureImages once when the component mounts
  //   setCultureImages(getRandomCultureImages());
  // }, []);

  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const timestamp = new Date().getTime();

        const response = await fetch(
          `https://api.airtable.com/v0/appQfVniek9Vq2z2e/tblOIZUpHni8TTPyz?timestamp=${timestamp}`,
          {
            headers: {
              Authorization:
                "Bearer patSiW0uvUNKHJXKD.4a5080807e67cbd513d556bf79ca0c9f2b8d3dbd7b55540b478b6390e809f385",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data from Airtable");
        }

        const data = await response.json();
        const newImages = data.records.map((record) => {
          const attachments = record.fields.Attachments;
          if (attachments && attachments.length > 0) {
            return attachments[0].thumbnails.full.url;
          }
          return null;
        });

        // Randomly select two unique images
        const randomIndices = [];
        while (
          randomIndices.length < 2 &&
          randomIndices.length < newImages.length
        ) {
          const randomIndex = Math.floor(Math.random() * newImages.length);
          if (!randomIndices.includes(randomIndex)) {
            randomIndices.push(randomIndex);
          }
        }

        // Use the functional form of setImages to ensure the correct state value
        setImages([newImages[randomIndices[0]], newImages[randomIndices[1]]]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the input is the phone number field
    if (name === "phoneNumber") {
      // Remove non-numeric characters
      const numericPhoneNumber = value.replace(/\D/g, "");

      // Enforce the character limit of 10
      const truncatedPhoneNumber = numericPhoneNumber.slice(0, 10);

      // Format the truncated phone number as ###.###.###
      const formattedPhoneNumber = truncatedPhoneNumber.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "$1.$2.$3"
      );

      setFormData({
        ...formData,
        [name]: formattedPhoneNumber,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleColorClick = (color) => {
    setFormData({
      ...formData,
      color,
    });
  };

  const searchForEmployeeImage = async (offset, retryCount = 0) => {
    try {
      const response = await fetch(
        `https://api.airtable.com/v0/appQfVniek9Vq2z2e/tblcnC19CtxdIIl9k?offset=${offset}`,
        {
          headers: {
            Authorization:
              "Bearer patSiW0uvUNKHJXKD.4a5080807e67cbd513d556bf79ca0c9f2b8d3dbd7b55540b478b6390e809f385",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data from Airtable");
      }

      const data = await response.json();
      var fileName = `${formData.firstName}${formData.lastName}.png`
        .toLowerCase() // convert to lowercase
        .replace(/\s/g, ""); // remove extra spaces

      // alert(fileName);

      // Log all unique entries
      const uniqueEntries = new Set(
        data.records.map((record) =>
          record.fields.Attachments[0].filename.toLowerCase()
        )
      ); // Convert to lowercase
      console.log("Unique Entries:", Array.from(uniqueEntries));

      // Find the record with the matching filename in the Airtable API response
      const matchingRecord = data.records.find((record) => {
        const attachments = record.fields.Attachments;
        return (
          attachments &&
          attachments.length > 0 &&
          attachments[0].filename.toLowerCase() === fileName
        );
      });

      if (matchingRecord) {
        return matchingRecord.fields.Attachments[0].thumbnails.full.url;
      } else if (data.offset && retryCount < 2) {
        // If there are no matching records and there's more data, retry with the new offset
        return searchForEmployeeImage(data.offset, retryCount + 1);
      } else {
        // If no matching record is found and no more data is available, return null
        return null;
      }
    } catch (error) {
      console.error("Error fetching data from Airtable:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const offset = ""; // Start with an empty offset
      const employeeImage = await searchForEmployeeImage(offset);

      const cleanedFirstName = formData.firstName.replace(/\s+/g, " ").trim();
      const cleanedLastName = formData.lastName.replace(/\s+/g, " ").trim();

      const fullName = `${cleanedFirstName} ${cleanedLastName}`;

      if (employeeImage) {
        setTimeout(() => {
          setResults((prevResults) => (
            <GeneratedSignature
              fullName={fullName}
              jobTitle={formData.jobTitle}
              phoneNumber={formData.phoneNumber}
              emailAddress={formData.emailAddress}
              address={formData.address}
              color={formData.color}
              employeeImage={employeeImage}
            />
          ));

          setLoading(false);
        }, 4000);
      } else {
        setTimeout(() => {
          // Handle the case where the image doesn't exist in the Airtable response
          setResults((prevResults) => (
            <GeneratedSignature
              fullName={fullName}
              jobTitle={formData.jobTitle}
              phoneNumber={formData.phoneNumber}
              emailAddress={formData.emailAddress}
              address={formData.address}
              color={formData.color} // Pass the color prop
              employeeImage={defaultEmployeeImage} // Pass null for the employee image
            />
          ));

          setLoading(false);
        }, 4000);
      }
    } catch (error) {
      setTimeout(() => {
        console.error("Error fetching data from Airtable:", error);
        setLoading(false);
      }, 4000);
    }
  };

  const handleGoBack = () => {
    setLoading(true); // Set loading to true

    // Simulate a delay before resetting the form and stopping the loader
    setTimeout(() => {
      setResults(null);
      setCopied(false);
      setFormData({
        firstName: `${formData.firstName}`,
        lastName: `${formData.lastName}`,
        jobTitle: `${formData.jobTitle}`,
        phoneNumber: `${formData.phoneNumber}`,
        emailAddress: `${formData.emailAddress}`,
        color: `${formData.color}`,
      });
      setLoading(false); // Set loading back to false after the delay
    }, 4000); // Adjust the timeout duration as needed
  };

  const handleCopyToClipboard = () => {
    const content = document.getElementById("content");
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(content);
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      // Attempt to copy the selected content
      const success = document.execCommand("copy");

      if (success) {
        console.log("Content copied to clipboard");
        // You may want to set a state variable or perform other actions here
      } else {
        console.error("Unable to copy content to clipboard");
        // Handle the case where copying fails
      }
    } catch (err) {
      console.error("Error copying content to clipboard:", err);
      // Handle any errors that may occur during copying
    }
  };

  return (
    <div className="container max-width-xl">
      <div className="grid gap-lg justify-center height-100vh items-center">
        {loading ? (
          <video
            className="loader"
            disablePictureInPicture
            playsInline
            autoPlay
            loop
            muted
          >
            <source src={loadinganimation} type="video/mp4" />
          </video>
        ) : results ? (
          <>
            <div>
              <Fade top cascade>
                <div className="grid gap-md justify-center margin-y-0">
                  <div className="col-12 margin-bottom-sm margin-top-lg margin-top-0@md">
                    <h1 className="margin-0 text-lg font-secondary ">
                      Email Signature Generator
                    </h1>
                  </div>
                </div>
              </Fade>
              <Fade top cascade>
                <div className="grid gap-md justify-center">
                  <div className="col-12 col@md">
                    <Fade top cascade>
                      <div
                        className="tableContainer padding-y-xl padding-x-lg radius-lg"
                        style={{
                          background: "white",
                        }}
                      >
                        <div
                          style={{
                            margin: "0 auto",
                          }}
                          id="content"
                          className="highlightable"
                        >
                          <p>{results}</p>
                        </div>
                      </div>

                      <div>
                        <div className="buttons-component sig-marquee">
                          <button className="btn button--habitat width-100%">
                            <span>Nice Headshot</span>
                            <div className="marquee" aria-hidden="true">
                              <div className="marquee__inner">
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                                <span>Nice Headshot</span>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </Fade>
                  </div>

                  <div className="col-12 col@md">
                    <div
                      className="padding-lg radius-lg"
                      style={{ background: "#E9E3DD", color: "#070F0B" }}
                    >
                      <h2 className="text-md font-secondary margin-bottom-xs">
                        Instructions:
                      </h2>
                      <ul className="font-primary instructions">
                        <li className="">Click Copy to Clipboard</li>
                        <li className="">
                          Outlook &gt; Settings &gt; Signatures &gt; Edit
                        </li>
                        <li className="">
                          Delete all the content and paste your email signature
                          &gt; Save
                        </li>
                        <li className="">
                          You can rename the “Standard” email signature to
                          Habitat
                        </li>
                        <li className="">
                          Under “Choose Default Signature” Select your email
                          signature for “New Messages” and “Replies/Forward”
                        </li>
                        <li className="">You're done!</li>
                      </ul>
                    </div>
                    <div className="text-center text-right@md">
                      <button
                        onClick={handleCopyToClipboard}
                        className="margin-top-md btn btn--primary padding-y-xs margin-right-sm"
                        id="highlightButton"
                      >
                        {copied ? "HTML Copied!" : "Copy to Clipboard"}
                      </button>
                      <Fade top cascade>
                        <button
                          className="margin-top-md btn btn--primary padding-y-xs"
                          onClick={handleGoBack}
                        >
                          Back
                        </button>
                      </Fade>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </>
        ) : (
          <>
            <div className="col-12 col@md margin-top-lg order-1 order-1@md">
              <Fade left>
                <div className="grid gap-md margin-bottom-0">
                  <div
                    className="col radius-lg  aspect-ratio-5:4"
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    <VideoComponent />
                  </div>
                </div>
                {/* <Airtable /> */}
              </Fade>

              <Fade left cascade>
                <div className="grid gap-md">
                  {images.map((image, index) => (
                    <div
                      key={index}
                      className="col radius-lg culture-container"
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        key={index}
                        src={image}
                        alt={`Employee Headshot ${index}`}
                        onError={(e) =>
                          console.log(`Error loading image: ${e.target.src}`)
                        }
                      />
                    </div>
                  ))}
                </div>
              </Fade>
            </div>

            <div className="col-12 col@md margin-top-sm margin-top-lg@md order-2 order-2@md">
              <Fade bottom>
                <h1 className="font-secondary text-lg ">
                  Email Signature Generator
                </h1>
              </Fade>

              <div id="emailsigform" className="margin-top-md">
                <Fade bottom cascade>
                  <form onSubmit={handleSubmit} className="">
                    <div>
                      <label className="block " htmlFor="first-name">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="first-name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block" htmlFor="last-name">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="last-name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block" htmlFor="job-title">
                        Job Title
                      </label>
                      <input
                        type="text"
                        id="job-title"
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block" htmlFor="phone-number">
                        Your Phone Number (Optional)
                      </label>
                      <input
                        type="tel"
                        id="phone-number"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        style={{ marginBottom: "0" }}
                      />
                      <p className="text-sm font-italic margin-top-xxxs margin-bottom-md">
                        If you leave this blank the agency phone number will be
                        added
                      </p>
                    </div>
                    <div>
                      <label className="block">
                        Select your background color:
                      </label>
                      <div className="grid gap-sm">
                        <Fade bottom cascade>
                          <div className="col">
                            <div
                              className={`radio-container bg-mint radius-md padding-left-xl padding-left-lg@md padding-right-sm padding-y-xs margin-y-sm  ${
                                formData.color === "#D4DFD7" ? "selected" : ""
                              }`}
                              onClick={() => handleColorClick("#D4DFD7")}
                            >
                              <input
                                type="radio"
                                id="mint"
                                name="color"
                                value="Mint"
                                checked={formData.color === "#D4DFD7"}
                                onChange={handleChange}
                              />
                              <label htmlFor="mint">Mint</label>
                              <span className="agency-radio"></span>
                            </div>

                            <div
                              className={`radio-container bg-coral radius-md padding-left-xl padding-left-lg@md padding-right-sm padding-y-xs margin-y-sm  ${
                                formData.color === "#F3B5AC" ? "selected" : ""
                              }`}
                              onClick={() => handleColorClick("#F3B5AC")}
                            >
                              <input
                                type="radio"
                                id="coral"
                                name="color"
                                value="Coral"
                                checked={formData.color === "#F3B5AC"}
                                onChange={handleChange}
                              />
                              <label htmlFor="coral">Coral</label>
                              <span className="agency-radio"></span>
                            </div>

                            <div
                              className={`radio-container bg-apricot radius-md padding-left-xl padding-left-lg@md padding-right-sm padding-y-xs margin-y-sm  ${
                                formData.color === "#EFCEAC" ? "selected" : ""
                              }`}
                              onClick={() => handleColorClick("#EFCEAC")}
                            >
                              <input
                                type="radio"
                                id="apricot"
                                name="color"
                                value="Apricot"
                                checked={formData.color === "#EFCEAC"}
                                onChange={handleChange}
                              />
                              <label htmlFor="apricot">Apricot</label>
                              <span className="agency-radio"></span>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`radio-container bg-mist radius-md padding-left-xl padding-left-lg@md padding-right-sm padding-y-xs margin-y-sm  ${
                                formData.color === "#D3DCE4" ? "selected" : ""
                              }`}
                              onClick={() => handleColorClick("#D3DCE4")}
                            >
                              <input
                                type="radio"
                                id="mist"
                                name="color"
                                value="Mist"
                                checked={formData.color === "#D3DCE4"}
                                onChange={handleChange}
                              />
                              <label htmlFor="mist">Mist</label>
                              <span className="agency-radio"></span>
                            </div>

                            <div
                              className={`radio-container bg-lavender radius-md padding-left-xl padding-left-lg@md padding-right-sm padding-y-xs margin-y-sm  ${
                                formData.color === "#BCA1D3" ? "selected" : ""
                              }`}
                              onClick={() => handleColorClick("#BCA1D3")}
                            >
                              <input
                                type="radio"
                                id="lavender"
                                name="color"
                                value="Lavender"
                                checked={formData.color === "#BCA1D3"}
                                onChange={handleChange}
                              />
                              <label htmlFor="lavender">Lavender</label>
                              <span className="agency-radio"></span>
                            </div>

                            <div
                              className={`radio-container bg-accent radius-md padding-left-xl padding-left-lg@md padding-right-sm padding-y-xs margin-y-sm  ${
                                formData.color === "#D0C4B7" ? "selected" : ""
                              }`}
                              onClick={() => handleColorClick("#D0C4B7")}
                            >
                              <input
                                type="radio"
                                id="tan"
                                name="color"
                                value="Tan"
                                checked={formData.color === "#D0C4B7"}
                                onChange={handleChange}
                              />
                              <label htmlFor="tan">Tan</label>
                              <span className="agency-radio"></span>
                            </div>
                          </div>
                        </Fade>
                      </div>
                      <button
                        className="btn btn--primary padding-y-xs"
                        type="submit"
                      >
                        Generate Signature
                      </button>
                    </div>
                  </form>
                </Fade>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default EmailSignatureForm;
